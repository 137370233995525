








































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'

@Component({
    name: 'integrationsDevelopers',
    components: {
        VsContainer,
    },
})
export default class extends Vue {
    get integrationTypes (): any[] {
        return [
            {
                logo: 'logo_prestashop.png',
                name: this.$t('developers.integrations.index.prestashopTitle'),
                description: this.$t('developers.integrations.index.prestashopDescription'),
                buttonLink: 'https://developers.4dem.it/article/680-prestashop',
            },
            {
                logo: 'logo_wordpress.png',
                name: this.$t('developers.integrations.index.wordPressTitle'),
                description: this.$t('developers.integrations.index.wordPressDescription'),
                buttonLink: 'https://developers.4dem.it/article/601-wordpress-e-woocomerce',
            },
            {
                logo: 'logo_woocommerce.png',
                name: this.$t('developers.integrations.index.wooCommerceTitle'),
                description: this.$t('developers.integrations.index.wooCommerceDescription'),
                buttonLink: 'https://developers.4dem.it/article/682-integrare-woocommerce',
            },
            {
                logo: 'logo_contact-form.png',
                name: this.$t('developers.integrations.index.contactFormTitle'),
                description: this.$t('developers.integrations.index.contactFormDescription'),
                buttonLink: 'https://developers.4dem.it/article/671-contact-form-7',
            },
            {
                logo: 'logo_magento.png',
                name: this.$t('developers.integrations.index.magentoTitle'),
                description: this.$t('developers.integrations.index.magentoDescription'),
                buttonLink: 'https://developers.4dem.it/article/659-magento-1-9',
            },
            {
                logo: 'logo_zapier.png',
                name: this.$t('developers.integrations.index.zapierTitle'),
                description: this.$t('developers.integrations.index.zapierDescription'),
                buttonLink: 'https://zapier.com',
            },
            {
                logo: 'logo_shopify.png',
                name: this.$t('developers.integrations.index.shopifyTitle'),
                description: this.$t('developers.integrations.index.shopifyDescription'),
                buttonLink: 'https://developers.4dem.it/article/739-shopify',
            },
            {
                logo: 'logo_facebook.png',
                name: this.$t('developers.integrations.index.facebookTitle'),
                description: this.$t('developers.integrations.index.facebookDescription'),
                buttonLink: 'https://developers.4dem.it/article/665-integrare-un-facebook-form-tramite-zapier',
            },
            {
                logo: 'logo_leadchampion.png',
                name: this.$t('developers.integrations.index.leadChampionTitle'),
                description: this.$t('developers.integrations.index.leadChampionDescription'),
                buttonLink: 'https://developers.4dem.it/article/725-integrazione-con-lead-champion',
            },
            {
                logo: 'logo_eventbrite.png',
                name: this.$t('developers.integrations.index.eventbriteTitle'),
                description: this.$t('developers.integrations.index.eventbriteDescription'),
                buttonLink: '/app/user/integration/eventbrite',
            },
            {
                logo: 'logo_tilby.png',
                name: this.$t('developers.integrations.index.tilbyTitle'),
                description: this.$t('developers.integrations.index.tilbyDescription'),
                buttonLink: '/app/user/integration/tilby',
            },
            {
                logo: 'logo_google-sheets.png',
                name: this.$t('developers.integrations.index.googleSheetsTitle'),
                description: this.$t('developers.integrations.index.googleSheetsDescription'),
                buttonLink: 'https://developers.4dem.it/article/741-google-sheets',
            },
            {
                logo: 'logo_googleform.jpg',
                name: this.$t('developers.integrations.index.googleFormTitle'),
                description: this.$t('developers.integrations.index.googleFormDescription'),
                buttonLink: 'https://developers.4dem.it/article/746-moduli-google',
            },
        ]
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get logo () {
        return get(UserModule, 'settings.theme.logoConsole', '')
    }

    beforeMount () {
        if (!this.is4Dem) this.$router.push({ name: 'dashboardIndex' })
    }

    private getLogo (logo: string) {
        return require('./../../../assets/img/integrations/' + logo)
    }
}

